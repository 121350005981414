//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { CompanyModel } from '@/modules/company-profile-new/company-model';
const { fields } = CompanyModel;
import firebase from 'firebase/app';
import 'firebase/storage';
import { EventBus } from '@/event-bus.js';


export default {
  name: 'app-company-commercial-register',
  props: ['value'],


  data() {
    return {
      commercialRegister: null,
      license: null,
      newCommercialRegister: null,
      newLicense: null,
      changeLicense: 0,
      changeCommercialRegister: 0,
      loadingImages: false
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      saveLoading: 'company/form/saveLoading'

    }),
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
    fields() {
      return fields;
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    editCompanyProfile() {},

    async uploadCommercialRegister(fileList){
      this.loadingImages = true
      console.log(fileList);                      
      this.commercialRegister = fileList[0] 
      this.newCommercialRegister = this.value[fields.commercialRegister.name]['publicUrl']
      this.changeCommercialRegister  = this.changeCommercialRegister + 1 
      if(this.changeCommercialRegister > 1 && this.commercialRegister) {
        const uploads = await this.uploadImage(`saryahCompany/company/${this.currentUser.companyId}/commercial register`, this.commercialRegister)
        console.log(uploads);
        this.newCommercialRegister = uploads[0].publicUrl
      }
      if(!this.commercialRegister){
        EventBus.$emit('formImageIsRequired', 'commercialRegister')
      }
      this.loadingImages = false
    },
    async uploadLicense(fileList){
      this.loadingImages = true
      console.log(fileList);                      
      this.license = fileList[0] 
      this.newLicense = this.value[fields.license.name]['publicUrl']
      this.changeLicense = this.changeLicense + 1
      if(this.changeLicense > 1 && this.license) {
        const uploads = await this.uploadImage(`saryahCompany/company/${this.currentUser.companyId}/license`, this.license)
        console.log(uploads);
        this.newLicense = uploads[0].publicUrl
      }
      if(!this.license){
        EventBus.$emit('formImageIsRequired', 'license')
      }
      this.loadingImages = false
    },

    async uploadImage(path, request){
      const uploads = [];
      const promises = [];
      const ref = firebase.storage().ref();
      const file = request.file
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const fullPath = `${path}/${timestamp}`;
      const task = ref.child(fullPath)
        const uploadPromise = task.put(file).then(async () => {
          const downloadURL = await task.getDownloadURL();
          uploads.push({
            name: file.name,
            publicUrl: downloadURL
          });
        });
        promises.push(uploadPromise);
      await Promise.all(promises);
      console.log(uploads);
      return uploads;
    },

  
    doSubmit(){
      this.$emit('updateCompanyProfile',{
        license: this.newLicense,
        commercialRegister: this.newCommercialRegister
      })
    },
     
  },
  async created(){
    console.log('commercial register ===> ',this.value);
  }
};
