//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { CompanyModel } from '@/modules/company/company-model';
import firebase from 'firebase/app';
import 'firebase/storage';
// import { FormSchema } from '@/shared/form/form-schema';
const { fields } = CompanyModel;
// const formSchema = new FormSchema([
//     fields.logo,
//     fields.name,
//     fields.address,
//     fields.languages,
//     fields.description,
//     fields.countryId,
//     fields.cityId,
//     fields.phoneNumber,
//     fields.email,
//     fields.websiteLink,
//     fields.facebookLink,
//     fields.twitterLink,
//     fields.instagramLink
// ]);
export default {
  name: 'app-company-profile',
  // props:{
  //   value:{
  //     type: Object,
  //   }
  // },
  props:['value'],
  data() {
    return {
      languages: [
          'Arabic',
          'English'
      ],
      countries: [

      ],
      countryCodes: [

      ],
      companyNameEnglish: '',
      companyNameArabic: '',
      description: '',
      country: '',
      city: '',
      code: '',
      phoneNumber: '',
      workEmailAddress: '',
      websiteLink: '',
      facebookLink: '',
      instagramLink: '',
      twitterLink: '',
      avatar: '',
      logo: '',

      model: {},

      options: [
        'Arabic', 'English', 'German', 'French', 'Spanish'
      ],

      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode: 'SA',
      language: this.isRTL ? 'en' : 'ar',

      citiesOptions: [],
      popupShow: false,

    };
  },
   components:{
    VuePhoneNumberInput,    
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading', 
    }),
    fields() {
      return fields;
    },
     isCCode() {
      if (!this.model.countryCode) {
        return this.CCode;
      } else {
        console.log(this.model.countryCode);
        return this.model.countryCode;
      }
    },
    isRTL(){
      return i18n.locale == 'ar'
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          label: item.name['en'],
        }
      })
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    editCompanyProfile() {
        
    },
    async uploadCompanyLogo(value){
      this.logo = value
      console.log(this.logo);
      debugger      
      const uploads = await this.uploadImage(`saryahCompany/company/${this.currentUser.companyId}/logo`, this.logo)
      console.log(uploads);
      this.value.logo.publicUrl = uploads[0].publicUrl
      // this.$emit('updateLogo', uploads[0].publicUrl)
      debugger
    },

    async uploadImage(path, file){
      const uploads = [];
      const promises = [];
      const ref = firebase.storage().ref();
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
        const fullPath = `${path}/${timestamp}`;
        const task = ref.child(fullPath)
        const uploadPromise = task.put(file).then(async () => {
          const downloadURL = await task.getDownloadURL();
          uploads.push({
            name: file.name,
            publicUrl: downloadURL
          });
          console.log('in push');
        });
        promises.push(uploadPromise);
      await Promise.all(promises);
      console.log(uploads);
      return uploads;
    },

    ...mapActions({
      doFetchCities: 'city/list/doFetch',
    }),
    onUpdate(data) {
      this.value.countryCode = data.countryCode;
      this.value.phoneNumber = data.formatNational
    },

    filterFn (val, update) {
      debugger
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        debugger
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    }
  },

  async mounted(){
    await this.doFetchCities()
  },
  
};
