import { render, staticRenderFns } from "./company-banking-account.vue?vue&type=template&id=2293565e&scoped=true&"
import script from "./company-banking-account.vue?vue&type=script&lang=js&"
export * from "./company-banking-account.vue?vue&type=script&lang=js&"
import style0 from "./company-banking-account.vue?vue&type=style&index=0&id=2293565e&lang=scss&scoped=true&"
import style1 from "./company-banking-account.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2293565e",
  null
  
)

export default component.exports
import {QCardSection,QInput,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QInput,QBtn})
