//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { CompanyModel } from '@/modules/company/company-model';
const { fields } = CompanyModel;

export default {
  name: 'app-company-banking-account',
    props:['value'],

  data() {
    return {
      commercialRegister: null,
      license: null,
      model: {},
      country: '',
      city: '',
      bankCity: ''
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
    fields() {
      return fields;
    },
  },
  watch:{
    value(newval){
      // console.log('value bankCity====>',newval.bankCity);
      // this.value.bankCity = newval.bankCity
      this.bankCity = newval.bankCity
      // console.log(newval);
      // console.log(this.bankCity);
      // debugger
    },
    // bankCity(newval){
    //   this.value.bankCity = newval
    // }
  },
  
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    editCompanyProfile() {},

    uploadCommercialRegister(fileList){
      console.log(fileList);                      
      this.commercialRegister = fileList[0]  
    },

    uploadLicense(fileList){
      console.log(fileList);                      
      this.license = fileList[0] 
    },
  },
  created(){
    console.log(this.value);
    // this.model = formSchema.initialValues(this.record);
    this.value.bankCity = this.bankCity
  }
};
