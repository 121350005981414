//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import CompanyProfile from '@/modules/company/components/company-profile.vue';
import CompanyCommercialRegister from '@/modules/company/components/company-commercial-register.vue';
import CompanyBankingAccount from '@/modules/company/components/company-banking-account.vue';

import { NotificationModel } from '@/modules/company/notification-model';
const { notificationFields } = NotificationModel
import { FormSchema } from '@/shared/form/form-schema';
const notificationFormSchema = new FormSchema([
    notificationFields.title,
    notificationFields.message,
    notificationFields.image,
    notificationFields.itemId,
    notificationFields.type,
    notificationFields.notes,
    notificationFields.updates,
]);


import { CompanyModel } from '@/modules/company/company-model';
const { fields } = CompanyModel;
const formSchema = new FormSchema([
    fields.logo,
    fields.name,
    fields.address,
    fields.languages,
    fields.description,
    fields.countryId,
    fields.cityId,
    fields.phoneNumber,
    fields.email,
    fields.websiteLink,
    fields.facebookLink,
    fields.twitterLink,
    fields.instagramLink,
    fields.bankName,
    fields.bankSwiftCode,
    fields.bankIban,
    fields.bankCity,
    fields.bankCountry,
    fields.commercialRegister,
    fields.license,
    fields.countryCode,
    fields.phoneNumber
]);

export default {
  name: 'app-comapny-profile',
  components: {
    [CompanyProfile.name]: CompanyProfile,
    [CompanyCommercialRegister.name]: CompanyCommercialRegister,
    [CompanyBankingAccount.name]: CompanyBankingAccount
  },

  data() {
    return {
      searchInput: '',
      model: {},
      notificationModel: {},
      oldModel: {}
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      record: 'company/view/record',
      oldRecord: 'company/view/oldRecord',
      notifcationRecord: 'company/form/record',
    }),

    fields() {
      return fields;
    },
    notificationsFields() {
      return notificationFields
    }
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  // watch:{
  //   oldRecord(newval){
  //     console.log(newval);
  //     debugger
  //   }
  // },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
       doFind: 'company/view/doFind',
       doCreateNotification: 'company/form/doCreate',
       doUpdateCompany:'company/form/doUpdateCompany'
    }),
    
    async doUpdateCompanyProfile(value){
      console.log('model ==> ',this.model);
      console.log('oldRecord ==> ',this.oldModel);
      debugger
      await this.doFind(this.currentUser.companyId)
      console.log('record ===> ',this.record);

      console.log('record city id',this.record.cityId);
      console.log('model city id',this.model.cityId);     

      let keys = ['name', 
      'address', 
      'description',
       'email', 
       'websiteLink',
        'facebookLink', 
        'twitterLink', 
        'instagramLink', 
        'cityId', 
        'countryCode', 
        'phoneNumber',
        'bankName',
        'bankSwiftCode',
        'bankIban',
        'bankCity', 
        'bankCountry'];
      let updates = {}

      keys.forEach((key) =>{
        if(JSON.stringify(this.model[key]) != JSON.stringify(this.record[key])){
          // let update = {} 
          updates[key] = this.model[key]
          debugger
          // updates.push(update)
        }
      })     
      if(this.record.logo.publicUrl != this.model.publicUrl){
        updates['logo'] = {}
        updates['logo'].publicUrl = this.model.logo.publicUrl
      }

      console.log(updates);
      if(value.license != this.model.license.publicUrl){        
        updates['license'] = {}
        updates['license'].publicUrl = this.model.license.publicUrl 
      }
      if(value.commercialRegister != this.model.commercialRegister.publicUrl){        
        updates['commercialRegister'] = {}
        updates['commercialRegister'].publicUrl = this.model.commercialRegister.publicUrl 
      }
      

      
      this.notificationModel.updates = updates
      console.log(this.notificationModel);

      debugger


      await this.doCreateNotification(this.notificationModel)
      // await this.doUpdateCompany({id:this.currentUser.companyId,data:this.model})
      
    }
  },


  async created(){
    this.model.name = this.model.name || { en: undefined,  ar: undefined }
    this.model.address = this.model.address || { en: undefined,  ar: undefined }
    this.model.description = this.model.description || { en: undefined,  ar: undefined }
    this.model.logo = this.model.logo || { publicUrl: undefined }
    this.model.commercialRegister = this.model.commercialRegister || { publicUrl: undefined }
    this.model.license = this.model.license || { publicUrl: undefined }

    console.log(this.currentUser.companyId);
    await this.doFind(this.currentUser.companyId)
    this.model = formSchema.initialValues(this.record);
    this.oldModel = formSchema.initialValues(this.oldRecord);
    // this.model = Object.assign({}, this.record)
    console.log(this.oldRecord);
    debugger

    this.notificationModel = notificationFormSchema.initialValues(this.notifcationRecord)
    this.notificationModel.title = { en: 'Update Company Profile', ar: 'Update Company Profile' }
    this.notificationModel.message = { en: `Company ${this.oldModel.name.en} wants to update some of their data`, ar: `Company ${this.oldModel.name.ar} wants to update some of their data`}
    this.notificationModel.itemId = this.currentUser.companyId
    this.notificationModel.image = this.record.logo.publicUrl
    this.notificationModel.type = 'companyUpdate'
    // this.notificationModel.action = null,
    // this.notificationModel.isNew = true, 
    // this.notificationModel.read = false,
    this.notificationModel.updates = {}
    // this.notificationModel.notes =  null

    console.log(this.notificationModel);
  }
};
