import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';
import JsonField from '@/shared/fields/json-field';


function label(name) {
  return i18n(`entities.notification.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  title: new StringField('title', label('title'),{}),
  message: new StringField('message', label('message'), {}),
  image: new ImagesField('image', label('image'), 'notification/image',{}),
  // name: new StringField('name', label('name'), {}),
  itemId: new StringField('itemId', label('itemId'), {}),
  type: new StringField('type', label('type'), {}),
  notes: new StringField('noted', label('notes'), {}),
  // action: ActionEnum
  updates: new JsonField('updates', label('updates')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class NotificationModel extends GenericModel {
  static get notificationFields() {
    return fields;
  }
}
